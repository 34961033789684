#header {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);

  &.white {
    color: white;
  }
}
