@import "../common.scss";
@import "../colors.scss";
@import "../fonts.scss";

#photos-page {
  background-color: #23262a;
  min-height: 100vh;

  #frame-1 {
    margin: 0 auto;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }

  .title {
    margin: 6rem 0 3rem;
    color: white;
    text-align: center;
    font-size: 40px;
  }

  .content {
    max-width: 1800px;
  }

  .desktop-content {
    @media only screen and (max-width: 850px) {
      display: none;
    }
  }

  .mobile-content {
    display: none;

    @media only screen and (max-width: 850px) {
      display: block;
    }

    img {
      width: 100%;
      margin-bottom: 1rem;
    }

    .single-col-gallery {
      margin-top: 2rem;
    }
  }

  .ReactGridGallery {
    margin-top: 2rem;
    div {
      justify-content: center;
    }
  }
}
