@import "../common.scss";
@import "../colors.scss";
@import "../fonts.scss";

#albums-page {
  background-color: #23262a;
  min-height: 100vh;

  #frame-1 {
    margin: 0 auto;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }

  .content {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 1fr;
    gap: 2rem;

    .album {
      position: relative;
      display: block;
      aspect-ratio: 1;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        filter: brightness(0.7);
        transition: all 0.15s ease;
        &:hover {
          filter: brightness(0.3);
        }
      }

      .album-title {
        width: 80%;
        h2,
        h3 {
          color: white;
          font-weight: 400;
        }
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
