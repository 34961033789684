html,
body {
  * {
    margin: 0;
  }
}

$drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

.content {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
